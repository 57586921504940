const { PORT } = require('../config');
export const GTM_ID_INTERNAL = 'GTM-PWDNDJG'
export const GTM_ID_EXTERNAL = 'GTM-5BH2PLS'
export const INTERNAL_PORTAL_URL = 'https://internal.docs.netomi.com'
export const EXTERNAL_PORTAL_URL = 'https://docs.netomi.com'
export const HOTJAR_ID_INTERNAL = '5218279'
export const HOTJAR_ID_EXTERNAL = '5218283'
export const HOTJAR_ID_LOCAL = '2628964'
export const SSO_LOGIN_URL_PROD = '/api/auth/sso/login'
export const SSO_LOGIN_URL_LOCAL = `http://localhost:${PORT}/api/auth/sso/login`
